<script>
import StaticPageLayout from '@/templates/static/StaticPageLayout'

export default {
  metaInfo() {
    return {
      title: 'Annotations submission service - Tools - Europe PMC',
    }
  },
  components: { StaticPageLayout },
  data() {
    return {
      title: 'Annotations submission service',
      sections: [
        {
          id: 'introduction',
          heading: 'h2',
          title: 'Introduction',
          collapsible: true,
          visible: true,
          highlight: true,
          scroll: true,
        },
        {
          id: 'ground-rules',
          heading: 'h2',
          title: 'Ground rules',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'getting-started',
          heading: 'h2',
          title: 'Getting started',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'submission-process',
          heading: 'h2',
          title: 'Submission process',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
        {
          id: 'data-format',
          heading: 'h2',
          title: 'Data Format',
          collapsible: true,
          visible: true,
          highlight: false,
          scroll: true,
        },
      ],
    }
  },
}
</script>
<template>
  <static-page-layout
    id="annotation-submission-service-page"
    :title="title"
    :sections="sections"
  >
    <template slot-scope="{ section }">
      <template v-if="section.id === 'introduction'">
        <p>
          The annotations submission service is a mechanism to publish
          annotations on the Europe PMC annotations platform.
        </p>
        <p>
          Simply put, you provide us with the annotations you wish to share, and
          we publish them on the Europe PMC website via SciLite and make them
          available through the
          <a href="/AnnotationsApi">Europe PMC Annotations API</a>.
        </p>
      </template>
      <ul v-if="section.id === 'ground-rules'">
        <li>
          Annotations should enrich the content of any abstract plus all Open
          Access full text articles contained in the Europe PMC platform by
          highlighting biological or methodological entities and providing links
          to related resources.
        </li>
        <li>
          Links to the biological entities tagged by annotations should be
          public and accessible without restrictions (no subscriptions, or login
          screens).
        </li>
        <li>
          All annotations published on the Europe PMC annotations platform will
          be considered public domain and will be published on the website and
          shared via the Europe PMC Annotations API.
        </li>
        <li>
          We strongly encourage any algorithms/code used to generate annotations
          sets to be sharable/open source.
        </li>
        <li>
          We reserve the right to take down annotations, if, for example, the
          content is not in scope, or is no longer reasonably maintained.
        </li>
      </ul>
      <template v-if="section.id === 'getting-started'">
        <p>
          Please email us at
          <a href="mailto:annotations@europepmc.org"
            >annotations@europepmc.org</a
          >, briefly describing the annotations you wish to share. We will get
          in touch with you and provide the information you need to generate the
          annotations datasets and upload them into the platform.
        </p>
        <p>
          Once you have generated annotation files according to the instructions
          below, you will be able to upload your data to the platform using your
          private Cloud Storage system.
        </p>
        <p>
          We have tried to make it easy, so that even if you do not have strong
          technical skills, it should still be possible to contribute.
        </p>
        <p>
          By using the Annotations Submission System you acknowledge that you
          have read and accepted the
          <a href="/PrivacyNoticeForEPMC?type=advanced-user-services"
            >Europe PMC Advanced User Services Privacy Notice</a
          >.
        </p>
      </template>
      <template v-if="section.id === 'submission-process'">
        <p>
          Once you have contacted us at
          <a href="mailto:annotations@europepmc.org"
            >annotations@europepmc.org</a
          >, we will provide you with the following information:
        </p>
        <ol>
          <li>A provider ID to use in the submitted files.</li>
          <li>
            URL for your private Cloud Storage system as well as username and
            password necessary to submit the annotations file. You are free to
            change those credentials whenever you want, but if you do that
            please let us know by writing an email to
            <a href="mailto:annotations@europepmc.org"
              >annotations@europepmc.org</a
            >
            as soon as possible. This will allow us to modify our system in
            order to be compliant with the new credentials.
          </li>
          <li>Specifications for the dataset.</li>
        </ol>
        <p>
          With the information mentioned above it is possible to submit the file
          containing the annotations to the platform, either using the web
          browser or programmatically. For more information see "<a
            href="//www.youtube.com/watch?v=tvKtOgl-Tlc"
            >How to share text mining results in biology</a
          >" video describing the process.
        </p>
        <p>
          Each submission will consist of a single file. Every file must have
          less than 10000 rows, where each row represents an individual article
          with all associated annotations. If your dataset contains more than
          10000 articles, and thus you have more than 10000 rows to upload, you
          can generate multiple files and then submit either a zip or a gzipped
          tar file containing all the data (unix command:
          <em>tar -czvf submission_file.tar.gz ./*</em>).
        </p>
        <p>
          If you use the browser, you can follow the procedure described and
          shown below:
        </p>
        <ul>
          <li>
            Go to the login page of the assigned private Cloud Storage system
            and login using your credentials
          </li>
          <li>
            Click on the "submissions" link on the left hand side of the page
          </li>
          <li>
            Click the "+" icon and then the "Upload file" icon on the right
            bottom part of the page to submit the file
          </li>
        </ul>
        <div>
          <img
            src="@/assets/annotations_submission.gif"
            alt="Annotations submission"
          />
        </div>
        <p>
          You can also submit files programmatically. To do that use the driver
          of the Cloud Storage System described
          <a href="//docs.min.io/docs/java-client-quickstart-guide">here</a>.
          There are drivers available in many different languages. Here is a
          simple example using Java Driver for submission:
        </p>
        <pre>
&nbsp;&nbsp;MinioClient minioClient = new MinioClient("https://annotations.europepmc.org", "your_username", "your_password");
&nbsp;&nbsp;File fileToSend= new File(fileName);
&nbsp;&nbsp;minioClient.putObject("submissions", fileToSend.getName(), new FileInputStream(fileToSend), "application/octet-stream");
			</pre
        >
        <p>
          Note that the URL specified in this case is different from the URL
          that you need to access from the browser to submit the file.
        </p>
        <p>
          Once the file is submitted, it will be processed by the submission
          system. The system is supposed to run every 60 minutes, so it could
          take some time to acknowledge new files. Once the process starts, you
          will receive two emails:
        </p>
        <ol>
          <li>
            The first email will notify you that your file is going to be
            processed and loaded in the system. You can expect the email subject
            to say "Loading of the Annotations file &lt;File Name&gt; of the
            provider &lt;Provider Name&gt; starting".
          </li>
          <li>
            A second email will be sent once the submission file has been
            processed. It will notify you about the outcome of the operation
            reflected in the email subject. If the operation is successful the
            subject will be "Annotations Loading of the file &lt;File Name&gt;
            performed successfully" and you can find the number of articles
            processed successfully in the email attachment. If the process fails
            the subject will be "Annotations Loading of the file &lt;File
            Name&gt; failed" and the email will state the reason for the loading
            fail, asking you to resolve identified problems and resubmit the
            data.
          </li>
        </ol>
        <p>
          The data about the submissions outcome can also be found once you are
          logged into the private Cloud Storage System using the "results" link
          on the left hand side of the browser. For example, if you submit a
          file "abstract.09_06_2018.tar.gz", you will find the relevant files
          containing the information sent by email in the submissions folder
          (file "Log_abstract.09_06_2018.tar.gz.txt"), and the results folder
          (file "Result_abstract.09_06_2018.tar.gz.txt").
        </p>
        <div>
          <img
            src="@/assets/annotations_submission_results.png"
            alt="Annotation submission results"
          />
        </div>
      </template>
      <template v-if="section.id === 'data-format'">
        <p>Each file must be UTF-8 encoded.</p>
        <p>
          Each row in the annotations file will contain one JSON object with all
          the annotations for a specific article:
        </p>
        <pre>
{ "src":"MED", "id": "27105176", "provider": "europepmc", "anns": [  { "position": "1.2", "prefix": "Noninvasive Markers to Assess ", "exact": "Liver Fibrosis", "section": "Title", "postfix": ". ", "tags": [ { "name": "Liver Fibrosis", "uri": "http://linkedlifedata.com/resource/umls-concept/C0239946" } ] }, {"position": "2.1", "prefix": "", "exact": "Chronic liver disease", "section": "Abstract", "postfix": " represents a major public health proble", "tags": [ { "name": "Chronic liver disease", "uri": "http://linkedlifedata.com/resource/umls-concept/C0341439" } ] }, {"position": "3.2", "prefix": " and progression of ", "exact": "liver fibrosis", "section": "Abstract", "postfix": " with time and the r", "tags": [ { "name": "liver fibrosis", "uri": "http://linkedlifedata.com/resource/umls-concept/C0239946" } ] }, {"position": "3.4", "prefix": "h time and the risk of development of ", "exact": "cirrhosis", "section": "Abstract", "postfix": ". ", "tags": [ { "name": "cirrhosis", "uri": "http://linkedlifedata.com/resource/umls-concept/C0023890" } ] }, {"position": "7.2", "prefix": "essing the presence and the degree of ", "exact": "liver fibrosis", "section": "Abstract", "postfix": ". ", "tags": [ { "name": "liver fibrosis", "uri": "http://linkedlifedata.com/resource/umls-concept/C0239946" } ] }, {"position": "8.2", "prefix": "e methods useful in the evaluation of ", "exact": "liver fibrosis", "section": "Abstract", "postfix": ". ", "tags": [ { "name": "liver fibrosis", "uri": "http://linkedlifedata.com/resource/umls-concept/C0239946" } ] } ] }
			</pre
        >
        <p>
          There are two types of the annotations in the platform: sentence-based
          annotations and named entity annotations.
        </p>
        <p>
          The JSON schema information that each object should adhere to varies
          according to the annotation type. More details as well as data
          validation guidelines before submission can be found
          <a href="//github.com/EuropePMC/EuropePMC-Annotation-Validator"
            >here</a
          >.
        </p>
        <p>An example of sentence-based annotations for one article:</p>
        <pre>
&nbsp;&nbsp;{
&nbsp;&nbsp;&nbsp;&nbsp;"src": "PMC" , #source of the article			
&nbsp;&nbsp;&nbsp;&nbsp;"id":"PMC5844054",  #identifier of the article in the context of the source field
&nbsp;&nbsp;&nbsp;&nbsp;"provider":"Disgenet" , #name of the provider
&nbsp;&nbsp;&nbsp;&nbsp;"anns": [
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"exact": ".... loss of SBP1 may play... aggressive disease.", # annotation sentence
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"section": "abstract",  #section of the article where the annotation was found.
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tags": [{    #tagged entities
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "... loss of SBP1 may play... aggressive disease.", #identifying name of the tagged entity
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"uri": "http://purl.uniprot.org/uniprot/Q13228"  #specific URI of the tagged entity
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}]	
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;},....#other annotations elements go here
&nbsp;&nbsp;&nbsp;&nbsp;]
&nbsp;&nbsp;}
				</pre
        >
        <p>An example of named entity annotations for one article:</p>
        <pre>
&nbsp;&nbsp;{
&nbsp;&nbsp;&nbsp;&nbsp;"src": "MED", #source of the article		
&nbsp;&nbsp;&nbsp;&nbsp;"id": "27105176", #identifier of the article in the context of the source field
&nbsp;&nbsp;&nbsp;&nbsp;"provider": "europepmc", #name of the provider
&nbsp;&nbsp;&nbsp;&nbsp;"anns": [
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"position": "1.2", #position of the entity in the article
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"prefix": "Noninvasive Markers to Assess ", #prefix of the entity inside the sentence of the article
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"postfix": ". ", #postfix of the entity inside the sentence of the article
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"exact": "Liver Fibrosis", # entity referred by the annotation
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"section": "Title",  #section of the article where the annotation was found.
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"tags": [{    #tagged entities
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"name": "Liver Fibrosis", #identifying name of the tagged entity
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;"uri": "http://linkedlifedata.com/resource/umls-concept/C0239946" #specific URI of the tagged entity
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;}]	
&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;},....#other annotations elements go here
&nbsp;&nbsp;&nbsp;&nbsp;]
&nbsp;&nbsp;}
			</pre
        >
        <p>Here is the list of fields with the relative explanations:</p>
        <div class="table-container">
          <table class="dark">
            <tbody>
              <tr>
                <th>Name</th>
                <th>Meaning</th>
                <th>Notes</th>
              </tr>
              <tr>
                <td>src</td>
                <td>Source of the article</td>
                <td>
                  Mandatory field. It has to be one of the following values:
                  <ul>
                    <li><b>MED</b>: PubMed MEDLINE abstract</li>
                    <li><b>PMC</b>: PubMedCentral full text article</li>
                    <li><b>PAT</b>: Patents</li>
                    <li><b>AGR</b>: Agricola (USDA/NAL)</li>
                    <li><b>CBA</b>: Chinese biological abstracts</li>
                    <li><b>HIR</b>: NHS Evidence (UK HIR)</li>
                    <li><b>CTX</b>: CiteXplore submission</li>
                    <li><b>ETH</b>: EThOS theses (BL)</li>
                    <li><b>CIT</b>: CiteSeer (PSU)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>id</td>
                <td>
                  Identifier of the article in the context of the src field
                  provided
                </td>
                <td>Mandatory field</td>
              </tr>
              <tr>
                <td>provider</td>
                <td>Name of the provider</td>
                <td>
                  Mandatory field. It must match the identifying name assigned
                  to the provider in the subscription phase
                </td>
              </tr>
              <tr>
                <td>anns</td>
                <td>List of annotations</td>
                <td>Mandatory field</td>
              </tr>
              <tr>
                <td>anns.position</td>
                <td>Position of the annotation</td>
                <td>
                  Mandatory field only for named entity annotations. We require
                  the relative order of mined entities within an article. This
                  information is used to help to locate and highlight mined
                  entities in the text. E.g., "1.3" means that the entity was
                  found in the third chunk of the first sentence of the article
                </td>
              </tr>
              <tr>
                <td>anns.prefix</td>
                <td>
                  Portion of the sentence that appears before the tagged entity
                </td>
                <td>
                  Relevant only for named entity recognition annotations. For
                  each annotation at least one field between prefix and postfix
                  must be specified
                </td>
              </tr>
              <tr>
                <td>anns.postfix</td>
                <td>
                  Portion of the sentence that appears after the tagged entity
                </td>
                <td>
                  Relevant only for named entity recognition annotations. For
                  each annotation at least one field between prefix and postfix
                  must be specified
                </td>
              </tr>
              <tr>
                <td>anns.exact</td>
                <td>Text of the tagged entity</td>
                <td>Mandatory field</td>
              </tr>
              <tr>
                <td>anns.section</td>
                <td>
                  Name of the section of the article where the tagged entity
                  appears
                </td>
                <td>
                  Optional field. For annotations datasets corresponding to a
                  full text article (src=PMC) the list of possible values is:
                  <ul>
                    <li>Title</li>
                    <li>Abstract</li>
                    <li>Introduction</li>
                    <li>Methods</li>
                    <li>Results</li>
                    <li>Discussion</li>
                    <li>Acknowledgments</li>
                    <li>References</li>
                    <li>Table</li>
                    <li>Figure</li>
                    <li>Case study</li>
                    <li>Supplementary material</li>
                    <li>Conclusion</li>
                    <li>Abbreviations</li>
                    <li>Competing Interests</li>
                    <li>
                      Article (default to use if the annotation can not be
                      mapped to any of the other sections)
                    </li>
                  </ul>
                  For any other article source the possible values are:
                  <ul>
                    <li>Title</li>
                    <li>Abstract (default)</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>anns.tags</td>
                <td>List of the entities tagged by this annotation</td>
                <td>
                  Mandatory field. This list should contain at least one tag
                </td>
              </tr>
              <tr>
                <td>anns.tags.name</td>
                <td>Name of the tagged entity</td>
                <td>Mandatory field</td>
              </tr>
              <tr>
                <td>anns.tags.uri</td>
                <td>
                  URI to the ID or the Accession number the entity is linked to
                  (e.g.: UniPort: http://purl.uniprot.org/uniprot/[Acs_number]).
                </td>
                <td>Mandatory field</td>
              </tr>
            </tbody>
          </table>
        </div>
      </template>
    </template>
  </static-page-layout>
</template>
<style scoped lang="scss">
.table-container {
  overflow-x: auto;
}
</style>
